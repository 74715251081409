.App {
  text-align: center;
  padding: 0 25%;

  @media (max-width: 700px) {
    padding: 0 10%;
  }

  @media (max-width: 450px) {
    padding: 0;
  }
}

.logo {
  height: 15vmin;
  @media (max-width: 700px) {
    height: 22vmin;
  }
}

.header {
  padding-top: 16px;
}

.title {
  @media (max-width: 700px);
}

.california {
  font-weight: 300;
}

.footer {
  padding: 48px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
}

.footerWrapper {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.image {
  height: 250px;
  width: auto;

  @media (max-width: 700px) {
    height: 200px;
  }
}

div.section {
  padding: 36px 0;
}

.description {
  padding: 16px 32px;
}

.distiller {
  font-weight: bold;
  padding-top: 24px;
}

.location {
  font-weight: bold;
  padding-top: 8px;
}
